@import '../../../../styles/basics';

.bb-where-your-money-goes-svg {
  @include bb-with-background-padding();
  min-height: 300px;
  transition: all .3s;

  h2 {
    text-align: left;
  }

  img {
    display: none;
    height: 300px;
    mask-image: linear-gradient(to left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 60%, rgba(0, 0, 0, 0) 100%);
  }
  .bb-where-your-money-goes-svg-container {
    display: flex;
    position: relative;
  }

  &__learn-more {
    margin: 0 0 16px 0;
    width: 100%;
    display: block;
    color: $turquoise-1000;
    border: 1px solid;
    border-radius: 25px;
    cursor: pointer;
    padding: 8px 28px;
    font-weight: 600;
    text-align: center;

    &:hover {
      text-decoration: none;
    }
  }
}

.bb-where-your-money-goes-svg-chart {
  width: 37%;
  margin-right: 4%;
}
.bb-where-your-money-goes-svg-info {
  position: relative;

  h3 {
    font-size: 40px;
    line-height: 30px;
    color: $slate-1200;
    margin-top: 2rem;
    margin-bottom: 10px;

    svg {
      display: inline;
      height: 26px;
      margin-left: 1rem;
      fill: $turquoise-1000;
    }

    span {
      font-size: 20px;
      color: $slate-1200;
      strong {
        background: linear-gradient(to bottom, transparent 25%, $turquoise-100 25%, $turquoise-100 85%, transparent 85%);
      }
    }
  }

  p {
    font-size: 18px;
  }
}

.bb-where-your-money-goes-svg-panel-toggle {
  bottom: 11px;
}

.bb-where-your-money-goes-svg-items {
  list-style-type: none;
  display: flex;
  justify-content: center;

  li {
    width: 27%;
    margin: 3%;
    margin-bottom: 0;
    text-align: left;
  }
}
.bb-where-your-money-goes-svg-panel {
  width: 100%;
  overflow: hidden;
  transition: all .3s;
  margin-top: -16px;
  background-color: $biscuit-100;
  box-shadow: 0px 1px 6px rgba(0,0,0,0.2);
  border-radius: 8px;
  text-align: center;
  max-height: 0;

  a {
    display: none;
  }
  .bb-where-your-money-goes-svg-panel-toggle {
    display: none;
  }
}
.bb-where-your-money-goes-svg-panel-opened {
  &.bb-where-your-money-goes-svg {
    min-height: 540px;
  }
  .bb-where-your-money-goes-svg-panel {
    max-height: 1000px;
    z-index: 1;
    padding-bottom: 40px;
    position: relative;

    li {
     display: block;
    }
    a {
      display: inline;
    }
  }
  .bb-where-your-money-goes-svg-chart-main {
    display: none;
  }
  .bb-where-your-money-goes-svg-chart-alt {
    display: block;
  }
}

.bb-where-your-money-goes-svg-chart-alt {
  display: none;
}

.bb-where-your-money-goes-svg-item {
  h4 {
    font-size: 28px;
    svg {
      height: 24px;
      margin-left: 1rem;
      path {
        fill: $slate-1000;
      }
    }
  }
  h5 {
    font-size: 18px;
  }
  p {
    font-size: 16px;
    margin-bottom: 0;
    min-height: 80px;
  }
  &::before {
    content: none;
  }
}

// With a big viewport font-sizes can be bigger.
@include media-breakpoint-up(xl) {
  .bb-where-your-money-goes-svg-info {
    padding-right: 40px;
    min-width: 550px;
    max-width: 700px;
    width: 100%;

    h3 {
      font-size: 50px;

      span {
        font-size: 24px;
      }
    }

    p {
      font-size: 20px;
    }
  }
  .bb-where-your-money-goes-svg img {
    display: block;
  }
}

@include media-breakpoint-down(lg) {
  .bb-where-your-money-goes-svg {
    max-height: 1000px;
    height: unset;
    transition: max-height .3s;

    .bb-where-your-money-goes-svg-container {
      display: block;
      max-width: unset;
      padding-right: 0;
      padding-left: 0;
    }
    img {
      display: none;
    }
    .bb-where-your-money-goes-svg-chart {
      width: 30%;
      max-width: 220px;
      margin-top: -22px;
      margin-left: 18px;
      float: right;
    }
    .bb-where-your-money-goes-svg-info {
      height: unset;
      //padding-bottom: 75px;
      width: 100%;
      strong {
        background: linear-gradient(to bottom, transparent 25%, $turquoise-100 25%, $turquoise-100 85%, transparent 85%);
      }
      h3 {
        line-height: 18px;
        margin-top: 3rem;
        span {
          font-size: 18px;
          display: block;
          margin-top: 1rem;
          line-height: $line-height-normal;
        }
      }
      p {
        margin-top: 26px;
        min-height: unset;
      }
      [data-component^="button"] {
        margin-top: 30px;
      }
      .bb-where-your-money-goes-svg-panel-toggle {
        width: 100%;
      }
    }
  }
  .bb-where-your-money-goes-svg-panel {
    box-shadow: none;
  }
  .bb-where-your-money-goes-svg-items {
    display: block;
    padding-left: 0;
    li {
      width: 100%;
      padding-left: 0;
      margin-top: 2rem;
      margin-left: 0;
    }
    p {
      min-height: unset;
    }
  }
  .bb-where-your-money-goes-svg-panel-opened.bb-where-your-money-goes-svg {
    max-height: 1000px;
    height: unset;
    .bb-where-your-money-goes-svg-chart-main {
      display: block;
    }
    .bb-where-your-money-goes-svg-chart-alt {
      display: none;
    }
    .bb-where-your-money-goes-svg-info {
      padding-bottom: 10px;
    }
    .bb-where-your-money-goes-svg-info .bb-where-your-money-goes-svg-panel-toggle {
      display: none;
    }
    .bb-where-your-money-goes-svg-panel {
      max-height: 1000px;
      min-height: unset;
      margin-top: -30px;
      padding-bottom: 0;

      .bb-where-your-money-goes-svg-panel-toggle {
        display: block;
        position: relative;
        margin: 2rem auto 0 auto;
        background-color: $biscuit-100;
        box-shadow: none;
        font-size: 16px;
        color: $turquoise-1000 !important;

        svg path {
          stroke: $turquoise-1000 !important;
        }
      }
    }
    a {
      display: block;
      width: 85%;
      margin: 0 auto;
    }
  }
}
